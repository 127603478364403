@tailwind base;

h1 {
  @apply text-4xl font-gotham font-bold antialiased text-black;
}

h2 {
  @apply text-3xl font-gotham font-semibold text-black;
}

h3 {
  @apply text-2xl font-gotham text-black;
}

h4 {
  @apply text-base font-gotham text-black;
}

h5,
p {
  @apply text-sm font-gotham font-light leading-tight text-black;
}

h6 {
  @apply text-xs font-gotham text-black;
}

li {
  @apply text-xs leading-4;
}

button:focus {
  outline: 0;
}

@font-face {
  font-family: "IndianRupee";
  src: url("./fonts/IndianRupee.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "GothamBook";
  font-weight: normal;
  src: url("./fonts/gotham/Gotham-Book.otf");
}

@font-face {
  font-family: "GothamBookLight";
  font-weight: 300;
  src: url("./fonts/gotham/Gotham-Light.otf");
}

@font-face {
  font-family: "GothamBookMedium";
  font-weight: 500;
  src: url("./fonts/gotham/Gotham-Medium.otf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: url("./fonts/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: "Poppins-Medium";
  font-weight: 500;
  src: url("./fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-Regular";
  font-weight: 500;
  src: url("./fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  font-weight: 500;
  src: url("./fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: "Roboto-Bold";
  font-weight: 500;
  src: url("./fonts/Roboto-Bold.ttf");
}
@font-face {
  font-family: "Poppins-Light";
  font-weight: 500;
  src: url("./fonts/Poppins-Light.ttf");
}


@tailwind components;

/* .error-text {
  @apply text-sm text-red font-gotham mt-2;
} */

@tailwind utilities;

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #ffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #d8eff3 0, #1ee939);
  background-image: linear-gradient(rgb(171, 235, 162) 0px, rgb(28, 218, 69));
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
}