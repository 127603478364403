@import 'style/font.css';
.page{
  width: 100%;
  overflow-x: hidden;
  animation: slideIn 0.3s;
 
}
@keyframes slideIn {
  0% {
    transform: translateX(200px) ;
  }
  100% {
    transform: translateX(0px) ;
  }
}
.page1{
  width: 100%;
  overflow-x: hidden;
  animation: slideOut 0.3s;
 
}
@keyframes slideOut {
  0% {
    transform: translateX(-200px) ;
  }
  100% {
    transform: translateX(0px) ;
  }
}

  /*my style*/
  .financier-list-item {
    background-color: #ffffff;
    /* padding: 20px; */
   
   
  
  }
  .financier-list-header {
  }
  .list_item{
    color:#5C6470;
  }
   
  .align_right {
    text-align: right;
  }
  
  .css-ya51ui-MuiPaper-root{
    box-shadow: none !important;
    border: solid 1px grey;
    border-radius: 2px !important;
  }
  
  .css-ya51ui-MuiPaper-root:first-child,.css-ya51ui-MuiPaper-root:nth-child(2), .css-ya51ui-MuiPaper-root:nth-child(3){
    border: none;
  }
  
  .sub-header{
    color: #191919;
    padding-left: 0px !important;
    mx:1px;
  }
  /* *{
    font-family: 'NeurialGrotesk';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 19.6px;
  } */
  .title{
    font-size: 20px;
    line-height: 28px;
    margin-top: 1px;
  }
.overflowContainer {
 
  height: 225px;
  overflow-y: auto;
}
/* Scroll bar css starts */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius:5px;
  
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

/* scroll bar ends */
  
.iconClass{
  font-size: 12px !important;
}
.backIconClass,.back_link{
  font-size: 10px !important;
}
.back_link{
  position: absolute;
  padding-left: 4px;
}
.align_center{
  align-self: center;
}
.slidee{
  animation-duration: 1s;
  animation-name: slidein;
  position:relative;
  margin: auto;
  height:350px !important;
  width: 722px !important;
  background-color: blue;
  
}
@keyframes slidein {
  from {
      margin-left: 100%;
      
  }

  to {
      margin-left: 0%;
      
  }
}

.main_light_label {
  color: #5C6470;
  font-size: 14px;

}
.main_dark_label {
  color: #191919;
  font-size: 14px;
}
.sub_light_label{
  color: #5C6470;
  font-size: 10px;
  margin-top: 5px;
}
.sub_dark_label{
  color: #191919;
  font-size: 10px;
  margin-top:5px;
  font-weight: 500;
}
.medium_label{
  color: #7B7B7B;
  font-size: 12px;
}
.emi_card {
  position: absolute;
  background-color: #FFDC48;
  border-radius: 2px;
  padding: 24px;
  width:37%;
  display: inline-block;

}

.label_container{
  display: inline-block;
  width:'60%';
}
.value_container{
  width:'40%';
  float: right;
}
.key_value_container {
  padding: 16px 0;

}
.MuiSlider-markLabel[data-index="0"]{
  left: 22px!important;
  color:#7B7B7B;
  
}
.MuiSlider-markLabel[data-index="1"]{
  left: 445px!important;
  color:#7B7B7B;
}
.container_details{
  background-color: #FFDC48;
  height: 194px;
  width: 274px;
  align-items: flex-end;
  margin-left: 32px;
  padding: 20px;
}
.main_container{
  width:452px;
  padding-right:32px;
}
.list_heading{
  font-size: 14px;
  margin-top:24px;
  color:#191919;

}
.list_title{
  margin-top: 24px;
}

