 
@font-face {
    font-family: NeurialGrotesk;
    src: url('font/NeurialGrotesk-Bold.otf') format('opentype');
    font-weight: 700;
    
}

@font-face {
    font-family: NeurialGrotesk;
    src: url('font/NeurialGrotesk-BoldItalic.otf') format('opentype');
    font-weight: BoldItalic;
}
@font-face {
    font-family: NeurialGrotesk;
    src: url('font/NeurialGrotesk-Extrabold.otf') format('opentype');
    font-weight: 800;
}
@font-face {
    font-family: NeurialGrotesk;
    src: url('font/NeurialGrotesk-ExtraboldItalic.otf') format('opentype');
    font-weight: ExtraboldItalic;
}
@font-face {
    font-family: NeurialGrotesk;
    src: url('font/NeurialGrotesk-Italic.otf') format('opentype');
    font-weight: italic;
}
@font-face {
    font-family: NeurialGrotesk;
    src: url('font/NeurialGrotesk-Light.otf') format('opentype');
    font-weight: 300;
}
@font-face {
    font-family: NeurialGrotesk;
    src: url('font/NeurialGrotesk-LightItalic.otf') format('opentype');
    font-weight: LightItalic;
}
@font-face {
    font-family: NeurialGrotesk;
    src: url('font/NeurialGrotesk-Medium.otf') format('opentype');
    font-weight: 500;
}
@font-face {
    font-family: NeurialGrotesk;
    src: url('font/NeurialGrotesk-MediumItalic.otf') format('opentype');
    font-weight: MediumItalic;
}
@font-face {
    font-family: NeurialGrotesk;
    src: url('font/NeurialGrotesk-Regular.otf') format('opentype');
    font-weight: 400;
}

@font-face {
    font-family: Rajdhani;
    src: url('font/Rajdhani.ttf');
}

@font-face {
    font-family: Rajdhani-Bold;
    src: url('font/Rajdhani-Bold.ttf');
    font-weight: bold;
}

@font-face {
    font-family: Ubuntu;
    src: url('font/Ubuntu.ttf');
}
